
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'

  import { MdxLayout } from '@redocly/developer-portal/ui';
import {
  Alert,
  LandingLayout,
  Button,
  Link,
  Box,
  FlexSection,
  Flex,
  Jumbotron,
  Typography,
  ThinTile,
  WideTile,
  Emphasis,
  H1,
  H2,
  SectionHeader,
  NavBar,
} from "@redocly/developer-portal/ui";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = MdxLayout
  }
  render() {
    const props = this.props
    const components = { wrapper: React.Fragment };


    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)}
             components={components}>


<MDXTag name="p" components={components}><H1>{`Overview`}</H1>{`
Welcome to Totogi's developer documentation. Here you can find technical documentation
for using the API, recipes on how to implement the most common use-cases, and a complete
API reference, including example requests and possible responses.`}</MDXTag>
<MDXTag name="p" components={components}><H2>{`Browse our API Catalog!`}</H2></MDXTag>
<Flex py={1} justifyContent="center" alignContent="space-between">
<ThinTile  to="../../openapi/aimanagement/overview/"  header="AI Management (TMF915)" color="#802dc8">
Defines the set of operations required to govern Artificial Intelligence systems deployed at scale. 
</ThinTile>
<ThinTile  to="../../openapi/account/overview/"  header="Account Management (TMF666)" color="#802dc8">
Management of billing, settlement and financial Accounts.
</ThinTile>
<ThinTile  to="../../openapi/agreement/overview/"  header="Agreement Management (TMF651)" color="#802dc8">
Management of Agreements, especially in the context of partnerships between partners.
</ThinTile>
</Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
<ThinTile  to="../../openapi/alarm/overview/"  header="Alarm Management (TMF642)" color="#802dc8">
Defines the set of operations required to manage Alarms. 
</ThinTile>
<ThinTile  to="../../openapi/appointmentmanagement/overview/"  header="Appointment (TMF646)" color="#802dc8">
Booking of an Appointment with all the necessary Appointment characteristics.
</ThinTile>
<ThinTile  to="../../openapi/customer/overview/"  header="Customer Management (TMF629)" color="#802dc8">
Customer and Customer Account management.
</ThinTile>
</Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
<ThinTile  to="../../openapi/customerbill/overview/"  header="Customer Bill Management (TMF678)" color="#802dc8">
Retrieval of Customer Bills and the details of applied rates presented on a Customer Bill.
</ThinTile>
<ThinTile  to="../../openapi/entitycatalog/overview/"  header="Entity Catalog Management (TMF662)" color="#802dc8">
Management of the entity specifications and their associations.
</ThinTile>
  <ThinTile
    to="../../openapi/geographicsitemanagement/overview/"
    header="Geographic Site Management (TMF674)"
    color="#802dc8"
  >
    Management of Sites that can be associated with a Customer, Account, Service
    delivery or other entities.
  </ThinTile>
</Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/party/overview/"
    header="Party Management (TMF632)"
    color="#802dc8"
  >
    Management of Party resource. Party can be an individual or an organization
    that has any kind of relationship with the enterprise.
  </ThinTile>
  <ThinTile
    to="../../openapi/partyinteraction/overview/"
    header="Party Interaction Management (TMF683)"
    color="#802dc8"
  >
    Captures information about past interactions in order to re-use it in future
    one.
  </ThinTile>
  <ThinTile
    to="../../openapi/partyrole/overview/"
    header="Party Role Management (TMF669)"
    color="#802dc8"
  >
    Management of Party Roles.
  </ThinTile>
</Flex>
  <Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/paymentmanagement/overview/"
    header="Payment Management (TMF676)"
    color="#802dc8"
  >
    Management of payments and refunds.
  </ThinTile>
  <ThinTile
    to="../../openapi/prepaybalance/overview/"
    header="Prepay Balance Management (TMF654)"
    color="#802dc8"
  >
   Includes all operations required to manage prepaid balances. 
  </ThinTile>
  <ThinTile
    to="../../openapi/privacy/overview/"
    header="Privacy Management (TMF644)"
    color="#802dc8"
  >
  Provides a standardized mechanism for management of privacy profile types, privacy profiles and privacy agreements.  
  </ThinTile>
   </Flex>
  <Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/productcatalog/overview/"
    header="Product Catalog Management (TMF620)"
    color="#802dc8"
  >
    Management of Product Catalog elements.
  </ThinTile>
  <ThinTile
    to="../../openapi/productinventory/overview/"
    header="Product Inventory Management (TMF637)"
    color="#802dc8"
  >
    Product Inventory management.
  </ThinTile>
  <ThinTile
    to="../../openapi/productofferingqualification/overview/"
    header="Product Offering Qualification (TMF679)"
    color="#802dc8"
  >
    Provides Product Offering commercial eligibility.
  </ThinTile>
  </Flex>
  <Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/productorder/overview/"
    header="Product Ordering (TMF622)"
    color="#802dc8"
  >
    Placement of a Product Order with all of the necessary order parameters.
  </ThinTile>
  
  <ThinTile
    to="../../openapi/promotion/overview/"
    header="Promotion (TMF671)"
    color="#802dc8"
  >
    Provides a standarized mechanism to manage additional discount, voucher, bonus or gift to be provided to customers who meet pre-defined criteria. 
  </ThinTile>
  <ThinTile
    to="../../openapi/quote/overview/"
    header="Quote Management (TMF648)"
    color="#802dc8"
  >
    Placement of a Customer Quote with all of the necessary quote parameters.
  </ThinTile>
</Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/resourcecatalog/overview/"
    header="Resource Catalog Management (TMF634)"
    color="#802dc8"
  >
    Allows management of the entire lifecycle of the Resource Catalog elements
    and the consultation of Resource Catalog elements during other processes.
  </ThinTile>
  
  <ThinTile
    to="../../openapi/resourceinventory/overview/"
    header="Resource Inventory Management (TMF639)"
    color="#802dc8"
  >
    Resource Inventory management.
  </ThinTile>
  <ThinTile
    to="../../openapi/resourceordering/overview/"
    header="Resource Ordering Management (TMF652)"
    color="#802dc8"
  >
    Placement of a Resource Order with all necessary order parameters.
  </ThinTile>
</Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/sales/overview/"
    header="Sales Management (TMF699)"
    color="#802dc8"
  >
    Management of Sales Lead, Sales Opportunity, Sales Quote capabilities to
    support the sales activities.
  </ThinTile>
  
  <ThinTile
    to="../../openapi/serviceactivation/overview/"
    header="Service Activation and Configuration (TMF640)"
    color="#802dc8"
  >
    Activation and configuration of the Services together with monitoring of the
    execution of asynchronous requests.
  </ThinTile>
  <ThinTile
    to="../../openapi/servicecatalog/overview/"
    header="Service Catalog (TMF633)"
    color="#802dc8"
  >
    Allows the management of the entire lifecycle of the Service Catalog
    elements.
  </ThinTile>
  </Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/serviceinventory/overview/"
    header="Service Inventory (TMF638)"
    color="#802dc8"
  >
    Provides a standardized mechanism to manage the Service inventory.
  </ThinTile>
  <ThinTile
    to="../../openapi/serviceordering/overview/"
    header="Service Ordering Management (TMF641)"
    color="#802dc8"
  >
    Placement of a Service Order with all of the necessary order parameters.
  </ThinTile>
  <ThinTile
    to="../../openapi/serviceproblem/overview/"
    header="Service Problem Management (TMF656)"
    color="#802dc8"
  >
    Management of Service Problems generated based on the information declared by a partner or the event information notified from infrastructure providers.
  </ThinTile>
  </Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/servicequalification/overview/"
    header="Service Qualification (TMF645)"
    color="#802dc8"
  >
    Provides service availability information at Customer location.
  </ThinTile>
<ThinTile
    to="../../openapi/servicequality/overview/"
    header="Service Quality Management (TMF657)"
    color="#802dc8"
  >
    Management of Service Level Specifications, associated Service Level Objectives and their thresholds.
  </ThinTile>
  <ThinTile
    to="../../openapi/servicetestmanagement/overview/"
    header="Service Test Management (TMF653)"
    color="#802dc8"
  >
    Placement of a Service Test with all of the necessary test parameters.
  </ThinTile>
 </Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
  <ThinTile
    to="../../openapi/shoppingcart/overview/"
    header="Shopping Cart (TMF663)"
    color="#802dc8"
  >
    Management of Shopping Carts. Shopping Cart is used for the temporary
    selection and reservation of product offerings in e-commerce.
  </ThinTile>
  
  <ThinTile
    to="../../openapi/stockmanagement/overview/"
    header="Stock Management (TMF687)"
    color="#802dc8"
  >
    Product Stock management.
  </ThinTile>
<ThinTile  to="../../openapi/troubleticket/overview/" header="Trouble Ticket (TMF621)" color="#802dc8">
Management of Trouble Tickets created as a result of an issue or problem identified by a customer or another system.
</ThinTile>
 </Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
<ThinTile  to="../../openapi/usagemanagement/overview/" header="Usage Management (TMF635)" color="#802dc8">
Management of rated and non-rated usage. Allows service provider to retrieve  usage generated by a partner service platform or to provide  rated usage to a partner for consumption.
</ThinTile>
<ThinTile  to="../../openapi/userrolepermission/overview/" header="User Roles and Permissions (TMF672)" color="#802dc8">
Management of User Roles and Permissions.
</ThinTile>
</Flex>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  